import styled from 'styled-components';

export const GameHandlerContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-wrap: nowrap;
` as any;

export const GameHandlerForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
`;
